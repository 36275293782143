if (typeof window !== 'undefined' && window.customElements) {
    class Spoiler extends HTMLElement {
    constructor() {
      super();
      this.expand = this.expand.bind(this);
    }

    connectedCallback() {
      this.addEventListener('click', this.expand);
      this.spoiler = this.textContent;
      console.log("custom element");
      this.innerHTML = '<a>(expand spoiler)<a>'
    }

    disconnectedCallback() {
      this.removeEventListener('click', this.expand);
    }

    expand() {
      this.innerHTML = this.spoiler;
    }
  }

  window.customElements.define('expand-spoiler', Spoiler);
}