import React, { Component } from 'react';
import { Link } from "gatsby";
import AccordionList from "../AccordionList/AccordionList";
import '../../styles/Blog.css';

function PostsCategory({item}) {
  return (
    <ul className="PostsList">
      {item.posts.map((listItem, index) =>
        <li key={listItem.slug}>
          <Link to={`/blog/${listItem.slug}/`}>
            {listItem.title}
          </Link>
          - <span className="publishMeta">{listItem.publishDateStr}</span>
        </li>
      )}
    </ul>
  )
}

class PostsList extends Component {
  constructor(props) {
    super(props);
    this.state = { postsCategorized: null, status: null };
  }

  fetchPosts() {
    this.setState({ status: 'LOADING' });

    return window.fetch('/api/blogPosts')
      .then(response => response.json())
      .then((result) => {
        this.setState({ postsCategorized: result, status: 'LOADED' });
      }).catch(e => {
        console.error( e);
        this.setState({ status: 'ERROR' });
      });
  }

  componentDidMount() {
    this.fetchPosts();
  }

  render() {
    const { postsCategorized, status } = this.state;
    return (
      <div className="content">
        {status === 'ERROR' ? (<div className="error-alert">Oops, a request to the server failed. Try reloading.</div>) : null}
        {postsCategorized ? (<AccordionList list={postsCategorized} shouldExpand={() => true} SlotComponent={PostsCategory}/>) : null}
      </div>
    );
  }
}

export default PostsList;
