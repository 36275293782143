import React, { Component } from 'react';
import "./Spoiler.js";
import "./HtmlViewer.css";

class HtmlViewer extends Component {
  getMarkup(contentItem) {
    return {
      __html: `<div>${contentItem.content}</div>`
    }
  }

  render() {
    return (
        <div className="HtmlViewer with-margin">
          {this.props.contentItem ? (<div>
            <h1 className="postHeader">{this.props.contentItem.title}</h1>
            <div className="aux-info-item">
              <div className="publishMeta">Posted {this.props.contentItem.publishDateStr}</div>
              <div className="postContent" dangerouslySetInnerHTML={this.getMarkup(this.props.contentItem)}/>
            </div>
          </div>) : null}
        </div>
    );
  }
}

export default HtmlViewer;
