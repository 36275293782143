import React, { Component } from 'react';
import HtmlViewer from "../HtmlViewer/HtmlViewer";
import Layout from '../Layout.js';
import PostsList from "./PostsList"

class BlogPost extends Component {
  render() {
    const post = this.props.pageContext;

    return (
        <Layout metaOptions={{
          title: post.title,
          description: post.content.substring(0, 120) + '...'
        }}>
          <div className="blogPost content">
            <div className="aux-info">
              <HtmlViewer contentItem={post}/>
            </div>
          </div>

          <PostsList/>
        </Layout>
    );
  }
}

export default BlogPost;
